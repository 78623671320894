<template>
  <a-modal
    v-model="show"
    title
    class="product-model"
    @cancle="cancle"
    :footer="null"
  >
    <div class="close-icon" slot="closeIcon" title="关闭">
      <i class="iconfont icon-danchuangguanbi-anniu"></i>
    </div>
    <div class="heade-top">
      <p class="top">
        <span class="star">*</span>
        <span style="margin-right: 8px">请输入型号：</span>
        <a-input
          v-if="(this.type == 'sale' || this.type == 'channel'|| this.type=='saleNew' || this.type == 'channelNew'||this.type == 'allot')"
          class="auto-input"
          v-model="model"
          style="width:360px"
          placeholder=""
          @keydown.enter="search"
        >
          <i
            class="iconfont icon-shouye-sousuo"
            slot="suffix"
            @click="search"
          />
        </a-input>
        <a-auto-complete
          v-else
          v-model="model"
          :data-source="modelList"
          class="auto-input"
          placeholder=""
          @search="onSearch"
          @select="search"
        >
          <template slot="dataSource">
            <a-select-option v-for="item in modelList" :key="item">
              {{ item }}
            </a-select-option>
          </template>
          <a-input
            v-model="model"
            style="width:360px"
            placeholder=""
            @keydown.enter="search"
          >
            <i
              class="iconfont icon-shouye-sousuo"
              slot="suffix"
              @click="search"
            />
          </a-input>
        </a-auto-complete>
        <h-btn @click="search" class="auto-btn" :width="88" :height="32"
        >查询
        </h-btn>
      </p>
      <div
        v-if="(this.type == 'sale' || this.type == 'channel'|| this.type=='saleNew' || this.type == 'channelNew'||this.type == 'allot')"
        class="searchBtn"
      >
        <a-checkbox
          class="Warehousing"
          @change="taojiChang('taoji')"
          v-model="taoji"
        >
          套机
        </a-checkbox>
        <a-checkbox
          style="margin-left:16px"
          class="Warehousing"
          @change="taojiChang('danji')"
          v-model="danji"
        >
          单机
        </a-checkbox>
        <a-checkbox
        v-if="this.type=='saleNew'"
          style="margin-left:16px"
          class="Warehousing"
          @change="taojiChang('yangji')"
          v-model="yangji"
        >
          样机
        </a-checkbox>
        <span style="color: #FF8F3C;font-size: 12px;margin-left: 45px;" v-if="searchTipShow">提示：T批次不可零售，此处不查T批次库存</span>
      </div>
      <p class="count-span"  v-show="filterData.length > 0">
        共搜索到 {{ pager.count || 0 }} 条数据
      </p>


    </div>
    <div class="dataNull" v-show="filterData.length <= 0">
      <div>
        <img src="../../../../assets/order/noList.png" alt="" />
        <p>暂无数据，请试试其他筛选条件~</p>
      </div>
    </div>
    <div v-show="filterData.length > 0">
      <hi-table
        v-if="show&&tabShow"
        ref="tb"
        :max-height="maxHeight"
        :data="filterData"
        :header="columns"
        :showLoading="tabeLoading"
        border
        :autoWidth="false"
        fit
        highlight-current-row
        @row-dblclick="addRow"
        @query="pageSearch"
      >
      <template slot="btnGroup" slot-scope="scope">
      <a class="btn-a" @click="addRow(scope.row)">添加</a>
        </template>
      </hi-table>
      <div class="page-check-wrap clearfix">
        <div class="check-wrap">
          <!--        <a-checkbox @change="onlyShowCheckedChange" :checked="onlyShowChecked">-->
          <!--          <span style="padding-left:8px">仅查看已选</span>-->
          <!--        </a-checkbox>-->
        </div>
        <div class="footer-pager">
          <Pagination :pager="pager" @query="getData('pageChange')" />
        </div>
      </div>
    </div>
    <div class="footer-btn-wrap">
      <h-btn
        class="h-btn"
        :width="188"
        :height="48"
        background="#AAAAAA"
        color="#FFFFFF"
        type="default"
        content="返回"
        @click="cancle"
        borderRadius="4"
      >返回
      </h-btn>
    </div>
    <div style="clear:both;width:100%;"></div>
  </a-modal>
</template>

<script>
import { getProductList, findProductListLikeCode,getRetailPrice,getfxPrice, } from "./api";
import { getStock } from "@/views/stockCenter/unsettledReservation/api";

export default {
  name: "ProductModal",
  props: {
    modelShow: {
      type: Boolean,
      required: true
    },
    orgId: {
      type: String,
      default: function() {
        return "";
      }
    },
    warehouseId: {
      type: String,
      default: function() {
        return "";
      }
    },
    rowSelectionType: {
      type: String,
      default: "checkbox" //radio
    },
    type: {
      type: String,
      required: true
    },
    shopCisCode: {
      type: String,
      default: function() {
        return "";
      }
    },
    cisCode: {
      type: String,
      default: function() {
        return "";
      }
    },
    isSpeclalShop: {
      type: String,
      default: function() {
        return "";
      }
    },
    XSLX: {
      type: String,
      default: function() {
        return "";
      }
    },
    xsmdId: {
      type: String,
      default: function() {
        return "";
      }
    },
    modelOrderNum: {
      type: Number,
      default: function() {
        return 0;
      }
    },
    AllOrderNum: {
      type: Number,
      default: function() {
        return 0;
      }
    },
    show4Message: {
      type: String,
      default: function() {
        return "";
      }
    },
    show5Message: {
      type: String,
      default: function() {
        return "";
      }
    },
    productList:{
      type:Array,
      default: () => []
    }
  },
  model: {
    prop: "modelShow",
    event: "input"
  },
  data() {
    return {
      maxHeight:500,
      show: false,
      tabShow: true,
      model: "",
      modelList: [],
      columns: [],
      dataCache: [],
      data: [],
      selRows: [],
      onlyShowChecked: false,
      pager: {
        count: "",
        pageNo: 1,
        pageSize: 10
      },
      tabeLoading:false,
      timer:null,
      taoji:true,
      danji:false,
      yangji:false,
      searchTipShow:false,// 搜索提示是否展示 (提示：T批次不可零售，此处不查T批次库存)
    };
  },
  beforeCreate() {
    try{
      this.maxHeight=window.innerHeigh - 350
    }catch (err){
      console.log(err)
    }
  },
  created(){
    this.judgeRoute()
  },
  computed: {
    //表格数据/切换只显示勾选使用
    filterData() {
      if (this.onlyShowChecked) {
        const productCodes = this.selRows.map(it => it.uid);
        const resu = this.data.filter(it => {
          return productCodes.includes(it.uid);
        });
        // console.log('表格数据1是############',resu);
        return resu;
      } else {
        // console.log('表格数据2是############',this.data);
        return this.data;
      }
    }
  },
  watch: {
    modelOrderNum: {
      handler: function(val) {
      }
    },
    AllOrderNum: {
      handler: function(val) {
      }
    },
    show4Message: {
      handler: function(val) {
      }
    },
    show5Message: {
      handler: function(val) {
      }
    },
    isSpeclalShop: {
      type: String,
      default: function() {
        return "1";
      }
    },
    XSLX: {
      type: String,
      default: function() {
        return "retail";
      }
    },

    modelShow: {
      handler: function(val) {
        this.show = this.modelShow;
        if (val) {
          this.pager = {
            count: 0,
            pageNo: 1,
            pageSize: 10
          };
          if (!this.model || this.model.length < 2) {
            this.selRows = []; //切换页码清空勾选的行
            this.onlyShowChecked = false;
            this.data = [];
            return;
          }
          this.getData();
        }
      },
      immediate: true
    },
    type: {
      handler: function(val) {
        if (this.type == "inApply") {
          this.columns = [
            {
              label: "型号",
              prop: "model",
              key: "model"
            },
            {
              label: "颜色",
              prop: "colour",
              key: "colour"
            },
            {
              label: "物料组",
              prop: "materialGroupName",
              key: "materialGroupName"
            },
            {
              label: "体积(m³)",
              prop: "volume",
              key: "volume"
            },
            {
              label: "操作",
              prop: "",
              key: "borderedQty",
              slot: "btnGroup"
            }
          ];
        }
        else if (this.type == 'sale' || this.type == 'channel' || this.type == 'saleNew'|| this.type == 'channelNew') {
          if (this.yangji) {
            this.columns = [
              {
                label: "物料类型",
                prop: "WLtype",
                ellipsis: true,
                key: "WLtype",
                width: "120",
              },
              {
                label: "型号",
                prop: "model",
                ellipsis: true,
                key: "model"
              },
              {
                label: "颜色",
                prop: "colour",
                width: "80",
                key: "colour"
              },
              {
                label: "物料组",
                prop: "materialGroupName",
                width: "100",
                ellipsis: true,
                key: "materialGroupName"
              },
              {
                label: "物料号",
                prop: "material",
                width: "130",
                ellipsis: true,
                key: "material"
              },
              {
                label: "组织",
                prop: "orgName",
                width: "100",
                ellipsis: true,
                key: "orgName"
              },
              {
                label: "价格(元)",
                prop: "retailPrice",
                width: "100",
                key: "retailPrice"
              },
              {
                label: "体积(m³)",
                prop: "volume",
                width: "110",
                key: "volume"
              },
              {
                label: "仓库",
                prop: "warehouseName",
                ellipsis: true,
                key: "warehouseName"
              },
              {
                label: "定价组",
                prop: "priceGroupName",
                key: "priceGroupName",
                width: "110",
              },
              {
                label: "质量等级",
                prop: "invStatusName",
                width: "110",
                key: "invStatusName"
              },
              {
                label: "补差类型",
                prop: "invStatusTypeName",
                width: "110",
                key: "invStatusTypeName"
              },
              {
                label: "库存状态",
                prop: "invTypeName",
                width: "110",
                key: "invTypeName"
              },

              {
                label: "可用库存",
                prop: "bigQty",
                width: "110",
                key: "bigQty"
              },
              {
                label: "操作",
                prop: "",
                key: "borderedQty",
                width: 80,
                slot: "btnGroup",
                fixed:this.type == 'saleNew'?"right":""
              }
            ]
          }else{
            this.columns = [
              {
                label: "物料类型",
                prop: "WLtype",
                ellipsis: true,
                key: "WLtype",
                width: "120",
              },
              {
                label: "型号",
                prop: "model",
                ellipsis: true,
                key: "model"
              },
              {
                label: "颜色",
                prop: "colour",
                width: "80",
                key: "colour"
              },
              {
                label: "物料组",
                prop: "materialGroupName",
                width: "100",
                ellipsis: true,
                key: "materialGroupName"
              },
              {
                label: "物料号",
                prop:"materialCode",
                width: "130",
                ellipsis: true,
                key: "materialCode"
              },
              {
                label: "组织",
                prop: "orgName",
                width: "100",
                ellipsis: true,
                key: "orgName"
              },
              {
                label: "价格(元)",
                prop: "retailPrice",
                width: "100",
                key: "retailPrice"
              },
              {
                label: "体积(m³)",
                prop: "volume",
                width: "110",
                key: "volume"
              },
              {
                label: "仓库",
                prop: "warehouseName",
                ellipsis: true,
                key: "warehouseName"
              },
              {
                label: "定价组",
                prop: "priceGroupName",
                key: "priceGroupName",
                width: "110",
              },
              {
                label: "质量等级",
                prop: "invStatusName",
                width: "110",
                key: "invStatusName"
              },
              {
                label: "补差类型",
                prop: "invStatusTypeName",
                width: "110",
                key: "invStatusTypeName"
              },
              {
                label: "库存状态",
                prop: "invTypeName",
                width: "110",
                key: "invTypeName"
              },

              {
                label: "可用库存",
                prop: "bigQty",
                width: "110",
                key: "bigQty"
              },
              {
                label: "操作",
                prop: "",
                key: "borderedQty",
                width: 80,
                slot: "btnGroup",
                fixed:this.type == 'saleNew'?"right":""
              }
            ]
          }
          
        }else if(this.type == 'statusApply'){
          this.columns = [
            {
              label: "物料类型",
              prop: "WLtype",
              ellipsis: true,
              key: "WLtype",
              width: "120",
            },
            {
              label: "型号",
              prop: "model",
              ellipsis: true,
              key: "model"
            },
            {
              label: "物料编码",
              prop: "model",
              ellipsis: true,
              key: "model"
            },
            {
              label: "颜色",
              prop: "colour",
              width: "80",
              key: "colour"
            },
            {
              label: "物料组",
              prop: "materialGroupName",
              width: "100",
              ellipsis: true,
              key: "materialGroupName"
            },
            {
              label: "价格(元)",
              prop: "retailPrice",
              width: "100",
              key: "retailPrice"
            },
            {
              label: "体积(m³)",
              prop: "volume",
              width: "110",
              key: "volume"
            },
            {
              label: "仓库",
              prop: "warehouseName",
              ellipsis: true,
              key: "warehouseName"
            },
            {
                label: "定价组",
                prop: "priceGroupName",
                key: "priceGroupName",
                width: "110",
              },
            {
              label: "质量等级",
              prop: "invStatusName",
              width: "110",
              key: "invStatusName"
            },
            {
              label: "补差类型",
              prop: "invStatusTypeName",
              width: "110",
              key: "invStatusTypeName"
            },
            {
              label: "可用库存",
              prop: "bigQty",
              width: "110",
              key: "bigQty"
            },
            {
              label: "实际库存",
              prop: "totalBig",
              width: "110",
              key: "bigQty"
            },
            {
              label: "占用库存",
              prop: "bigQtyLock",
              width: "110",
              key: "bigQty"
            },
            {
              label: "操作",
              prop: "",
              key: "borderedQty",
              width: 80,
              slot: "btnGroup"
            }
          ]
        } else{
          this.columns = [
            {
              label: "物料类型",
              prop: "WLtype",
              ellipsis: true,
              key: "WLtype",
              width: "120",
            },
            {
              label: "型号",
              prop: "model",
              ellipsis: true,
              key: "model"
            },
            {
              label: "颜色",
              prop: "colour",
              width: "80",
              key: "colour"
            },
            {
              label: "物料组",
              prop: "materialGroupName",
              width: "100",
              ellipsis: true,
              key: "materialGroupName"
            },
            {
              label: "价格(元)",
              prop: "retailPrice",
              width: "100",
              key: "retailPrice"
            },
            {
              label: "体积(m³)",
              prop: "volume",
              width: "110",
              key: "volume"
            },
            {
              label: "仓库",
              prop: "warehouseName",
              ellipsis: true,
              key: "warehouseName"
            },
            {
                label: "定价组",
                prop: "priceGroupName",
                key: "priceGroupName",
                width: "110",
              },
            {
              label: "质量等级",
              prop: "invStatusName",
              width: "110",
              key: "invStatusName"
            },
            {
              label: "补差类型",
              prop: "invStatusTypeName",
              width: "110",
              key: "invStatusTypeName"
            },
            {
              label: "可用库存",
              prop: "bigQty",
              width: "110",
              key: "bigQty"
            },
            {
              label: "操作",
              prop: "",
              key: "borderedQty",
              width: 80,
              slot: "btnGroup"
            }
          ]
        }
        // console.log('this.columns############',this.columns);
        // console.log(this.type)
      },
      immediate: true
    },
    yangji: {
      handler: function(val) {
          if (this.yangji) {
            this.columns = [
              {
                label: "物料类型",
                prop: "WLtype",
                ellipsis: true,
                key: "WLtype",
                width: "120",
              },
              {
                label: "型号",
                prop: "model",
                ellipsis: true,
                key: "model"
              },
              {
                label: "颜色",
                prop: "colour",
                width: "80",
                key: "colour"
              },
              {
                label: "物料组",
                prop: "materialGroupName",
                width: "100",
                ellipsis: true,
                key: "materialGroupName"
              },
              {
                label: "物料号",
                prop: "material",
                width: "130",
                ellipsis: true,
                key: "material"
              },
              {
                label: "组织",
                prop: "orgName",
                width: "100",
                ellipsis: true,
                key: "orgName"
              },
              {
                label: "价格(元)",
                prop: "retailPrice",
                width: "100",
                key: "retailPrice"
              },
              {
                label: "体积(m³)",
                prop: "volume",
                width: "110",
                key: "volume"
              },
              {
                label: "仓库",
                prop: "warehouseName",
                ellipsis: true,
                key: "warehouseName"
              },
              {
                label: "定价组",
                prop: "priceGroupName",
                key: "priceGroupName",
                width: "110",
              },
              {
                label: "质量等级",
                prop: "invStatusName",
                width: "110",
                key: "invStatusName"
              },
              {
                label: "补差类型",
                prop: "invStatusTypeName",
                width: "110",
                key: "invStatusTypeName"
              },
              {
                label: "库存状态",
                prop: "invTypeName",
                width: "110",
                key: "invTypeName"
              },

              {
                label: "可用库存",
                prop: "bigQty",
                width: "110",
                key: "bigQty"
              },
              {
                label: "操作",
                prop: "",
                key: "borderedQty",
                width: 80,
                slot: "btnGroup",
                fixed:this.type == 'saleNew'?"right":""
              }
            ]
          }else{
            this.columns = [
              {
                label: "物料类型",
                prop: "WLtype",
                ellipsis: true,
                key: "WLtype",
                width: "120",
              },
              {
                label: "型号",
                prop: "model",
                ellipsis: true,
                key: "model",
                width:160
              },
              {
                label: "颜色",
                prop: "colour",
                width: "80",
                key: "colour"
              },
              {
                label: "物料组",
                prop: "materialGroupName",
                width: "100",
                ellipsis: true,
                key: "materialGroupName"
              },
              {
                label: "物料号",
                prop:"materialCode",
                ellipsis: true,
                key: "materialCode",
                width:180
              },
              {
                label: "组织",
                prop: "orgName",
                width: "100",
                ellipsis: true,
                key: "orgName"
              },
              {
                label: "价格(元)",
                prop: "retailPrice",
                width: "100",
                key: "retailPrice"
              },
              {
                label: "体积(m³)",
                prop: "volume",
                width: "110",
                key: "volume"
              },
              {
                label: "仓库",
                prop: "warehouseName",
                ellipsis: true,
                key: "warehouseName",
                width:180
              },
              {
                label: "定价组",
                prop: "priceGroupName",
                key: "priceGroupName",
                width: "110",
              },
              {
                label: "质量等级",
                prop: "invStatusName",
                width: "110",
                key: "invStatusName"
              },
              {
                label: "补差类型",
                prop: "invStatusTypeName",
                width: "110",
                key: "invStatusTypeName"
              },
              {
                label: "库存状态",
                prop: "invTypeName",
                width: "110",
                key: "invTypeName"
              },

              {
                label: "可用库存",
                prop: "bigQty",
                width: "110",
                key: "bigQty"
              },
              {
                label: "操作",
                prop: "",
                key: "borderedQty",
                width: 80,
                slot: "btnGroup",
                fixed:this.type == 'saleNew'?"right":""
              }
            ]
          }
      },
      immediate: true
    },
    show: {
      handler: function(val) {
        this.$emit("input", val);
      }
    }
  },
  methods: {
    taojiChang(type) {
      //判断是否是新销售订单录入页面打开modal
      if(this.type=='saleNew'){
        //判断是否套、单、样三者均为关闭状态
        let isAnyChecked=this.taoji||this.danji||this.yangji
        // console.log('isAnyChecked##########',isAnyChecked);
        //当点击时，若是三者均关闭状态，则最后点击的那个强制恢复为打开状态
        if(!isAnyChecked){
          switch (type){
            case "taoji":
              this.taoji=true
            break
            case "danji":
              this.danji=true
            break
            case "yangji":
              this.yangji=true
            break

          }
          return
        }else{
          //否则放开互斥单选
          if (type == "taoji") {
            this.danji = false
            this.yangji=false
            this.$forceUpdate()
            this.getData()
          } else if(type == "danji"){
            this.taoji = false;
            this.yangji=false
            this.$forceUpdate()
            this.getData()
          }else{
            this.taoji = false;
            this.danji=false
            this.$forceUpdate() 
            this.getData()
          }
        }
      }else{
        //如果不是打开自新零售订单录入页面，则恢复原始套、单机互斥单选
        if (type == "taoji") {
          this.danji = false
          this.$forceUpdate()
        } else if(type == "danji"){
          this.taoji = false;
          this.$forceUpdate()
        }
      }
      
      // if (type == "taoji") {
      //   this.danji = false
      //   this.yangji=false
      //   this.$forceUpdate()
      // } else if(type == "danji"){
      //   this.taoji = false;
      //   this.yangji=false
      //   this.$forceUpdate()
      // }else{
      //   this.taoji = false;
      //   this.danji=false
      //   this.$forceUpdate() 
      // }
      this.pager.pageNo = 1;
      if(this.model) {
        this.getData();
      }

    },
    addRow(row) {
      // console.log('点击添加时的行数据是###############',row);
      // console.log('点击添加时的this.productList是###############',this.productList);
      // if(row.priceGroupName === '工程机' && row.inOrgCode && row.inOrgCode.startsWith("68")){
      //     this.$message.warning('不支持定价组为工程机的产品')
      //     return
      // }
      if(!row.bigQty>0){
          this.$message.warning('当前可用库存不足，暂时无法添加')
          return
      }
      if(this.type == 'saleNew' && this.XSLX == 'retail' && this.isSpeclalShop==1) {
        let sum = 0
        let allSum = 0
        //为this.productList填入数据
        this.productList.forEach(item=>{
          if(row.model == item.model){
            //若所选产品已存在则数量+1
            sum += Number(item.borderedQty)
          }
          allSum +=  Number(item.borderedQty)
        })
        if(sum >=  Number(this.modelOrderNum) ) {
          this.$message.warning(this.show4Message)
          return
        }
        if(allSum >=  Number(this.AllOrderNum) ) {
          this.$message.warning(this.show5Message)
          return
        }
        this.tabeLoading = true
        const selRows = JSON.parse(JSON.stringify([row]));
        this.$emit("submit", selRows);
        this.timer = setTimeout(() => {  //设置延迟执行
          this.tabeLoading = false;
        }, 1000);
        return

      }

      this.tabeLoading = true
      const selRows = JSON.parse(JSON.stringify([row]));
      this.$emit("submit", selRows);

      if(this.type !== 'saleNew' && this.type !== 'channelNew') {
        this.$message.info("添加成功，请关闭弹窗或继续添加!");
      }
      this.timer = setTimeout(() => {  //设置延迟执行
        this.tabeLoading = false;
      }, 1000);
    },
    //获取型号数据
    onSearch() {
      const data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        warehouseId: "",
        // warehouseId: this.warehouseId,
        productCode: this.model,
        orgId: this.orgId,
        page: {
          pageNo: 1,
          pageSize: 50
        }
      };
      findProductListLikeCode(data).then(res => {
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        this.modelList = [...new Set(data.products.map(it => it.model))]; //缓存列表数据，前端分页
      });
    },
    //行选中，取消
    onChange(selKeys, selRows) {
      this.selRows = selRows;
    },
    //只显示勾选
    onlyShowCheckedChange(e) {
      this.onlyShowChecked = e.target.checked;
    },
    //确认按钮
    submit() {
      if (this.selRows.length == 0) {
        this.$message.warning("请选择产品!");
        return;
      }
      const selRows = JSON.parse(JSON.stringify(this.selRows));
      this.$emit("submit", selRows);
      this.$emit("input", false);
    },
    //取消或者关闭
    cancle() {
      this.$emit("input", false);
    },
    //生成不重复id/后端返回的列表没有唯一键
    genID(length) {
      return Number(
        Math.random()
          .toString()
          .substr(3, length) + Date.now()
      ).toString(36);
    },
    //搜索按钮事件
    search() {
      if (!this.model || this.model.length < 2) {
        this.$message.info("请输入至少两个字符进行搜索！");
        return;
      }
      this.pager.pageNo = 1;
      this.getData();
    },
    pageSearch(pager){
      this.pager.pageNo = pager.pageNo;
      this.pager.pageSize = pager.pageSize;
      this.getData();
    },
    //调接口获取数据
    getData(type) {
      if (this.type == "inApply") {
        const data = {
          cisCode: this.$store.state.user.userInfo.customer.cisCode,
          // warehouseId: "",
          warehouseId: this.warehouseId,
          productCode: this.model,
          orgId: this.orgId,
          page: {
            pageNo: this.pager.pageNo,
            pageSize: this.pager.pageSize
          }
        };

        findProductListLikeCode(data).then(res => {

          let data;
          if (typeof res.data == "string") {
            data = JSON.parse(res.data);
          } else {
            data = res.data;
          }

          const start = (this.pager.pageNo - 1) * this.pager.pageSize;
          data.products.forEach(it => {
            it.volume = new Number(it.volume).toFixed(3)
            it.borderedQty = 1;
            it.invStatus = it.invType;
            it.bigUom = it.uom;
            it.uid = this.genID(10);
          });
          this.selRows = []; //切换页码清空勾选的行
          this.onlyShowChecked = false;
          this.pager.count = data.page.totalCount;
          this.dataCache = data.products; //缓存列表数据，前端分页
          this.data = data.products

        });
      }
      // 如果是零售录入或者渠道录入 用新的接口 查询物料号
      else if(this.type == 'sale' || this.type == 'channel'|| this.type=='saleNew' || this.type == 'channelNew'||this.type == 'allot') {
        {
          const data = {
            cisCode: this.$store.state.user.userInfo.customer.cisCode,
            terms: {
              model: this.model,
              colour: "",
              warehouseId:this.warehouseId,
              invStatusId: "",
              isLock: "",
              dealerMaterialGroupFlag: "",
              materialGroupCode: "",
              materialGroupName: "",
              gicWarehouseType: "",
              invStatusType: "",
              invType: "110",
              gicInvStatus:'110',
              bigQtyMin: 0,
              orgId: this.orgId,
            },
            page: {
              pageNo: this.pager.pageNo,
              pageSize: this.pager.pageSize
            }
          };
          this.tabeLoading = true
          // getProductListlr(data).then(res => {
          //   let data;
          //   if (typeof res.data == "string") {
          //     data = JSON.parse(res.data);
          //   } else {
          //     data = res.data;
          //   }
          let url=""
          //新增：如果是打开自新零售订单录入页面
          if(this.type=='saleNew'){
            //如果样机被勾选
            if(this.yangji){
              url = "findSampleNoPage"
              data.terms.storeCode=this.xsmdId
            }else{
              if(this.taoji) {
                // 套机勾选则是否包含单机标识为false或空
                data.terms.selectBom = false
                data.terms.isProductQuery=true
              } else {
                // 单机勾选则是否包含套机标识为false或空
                data.terms.selectBom = ''
                data.terms.isProductQuery=true
              }
              url = this.taoji?'findMaterialInventoryPage':'findMobileSingleMaterialInvList'
            }
          }else{
            if(this.taoji) {
              // 套机勾选则是否包含单机标识为false或空
              data.terms.selectBom = false
            } else {
              // 单机勾选则是否包含套机标识为false或空
              data.terms.selectBom = ''
            }
            // 根据是否为套机确定采用的接口
            url = this.taoji?'findMaterialInventoryPage':'findMobileSingleMaterialInvList'
          }

          // if(this.taoji) {
          //   // 套机勾选则是否包含单机标识为false或空
          //   data.terms.selectBom = false
          // } else {
          //   // 单机勾选则是否包含套机标识为false或空
          //   data.terms.selectBom = ''
          // }
          // // 根据是否为套机确定采用的接口
          // const url = this.taoji?'findMaterialInventoryPage':'findMobileSingleMaterialInvList'
          getStock(data, url).then(res => {
            let data;
            if (typeof res.data == "string") {
              data = JSON.parse(res.data);
            } else {
              data = res.data;
            }
            // console.log('查询结果data########',data);

            // const start = (this.pager.pageNo - 1) * this.pager.pageSize;
            data.data.forEach(it => {
              // console.log("查询方法中遍历的it是@@@@@@@@@@@@@@@@",it);
              if (this.yangji) {
                it.WLtype ="样机"
              }else{
                it.WLtype = (it.taoji?'套机':'单机')
              }
              if(this.type=='sale'||this.type=='channel'|| this.type=='saleNew' || this.type == 'channelNew'){
                it.borderedQty = 1;
                it.invStatus = it.invStatusId;
                it.bigUom = it.uom;
              }else{
                it.borderedQty = 1;
                it.invStatus = it.invType;
                it.bigUom = it.uom;
              }
              it.uid = this.genID(10);
            });

            if(this.type=='sale'|| this.type=='saleNew'){
              // console.log("data.data############",data.data);
              for (const i in data.data) {
                getRetailPrice(
                  {
                    productId:data.data[i].productCode,
                    isSampleToSales:data.data[i].WLtype=='样机'?1:0
                    }
                  ).then(el=>{
                  this.data[i].retailPrice = el.data.price
                  this.tabShow = false
                  this.$nextTick(()=>{
                    this.tabShow = true
                  })
                })
              }
            }
            else if(this.type=='channel'|| this.type == 'channelNew'){
              for (const i in data.data) {
                getfxPrice({productId:data.data[i].productCode,orgId:data.data[i].orgCode,cisCode: this.cisCode}).then(res=>{
                  this.data[i].retailPrice = res.data.list[0].standardPrice
                  this.data[i].standardPrice = res.data.list[0].standardPrice
                  this.tabShow = false
                  this.$nextTick(()=>{
                    this.tabShow = true
                  })
                })
              }
            }
            this.tabeLoading = false
            this.selRows = []; //切换页码清空勾选的行
            this.onlyShowChecked = false;
            this.pager.count = data.page.totalCount;
            this.dataCache = data.data; //缓存列表数据，前端分页
            this.data = data.data
          });
        }
      }
      else {
        const data = {
          cisCode: this.$store.state.user.userInfo.customer.cisCode,
          terms: {
            model: this.model,
            colour: "",
            warehouseId: this.warehouseId,
            invStatusId: "",
            isLock: "",
            dealerMaterialGroupFlag: "",
            materialGroupCode: "",
            materialGroupName: "",
            gicWarehouseType: "",
            invStatusType: "",
            invType: "110",
            gicInvStatus:'110',
            bigQtyMin: 0,
            orgId: this.orgId,
          },
          page: {
            pageNo: this.pager.pageNo,
            pageSize: this.pager.pageSize
          }
        };
        getProductList(data).then(res => {
          let data;
          if (typeof res.data == "string") {
            data = JSON.parse(res.data);
          } else {
            data = res.data;
          }
          const start = (this.pager.pageNo - 1) * this.pager.pageSize;
          data.data.forEach(it => {
            if(this.type=='sale'||this.type=='channel'){
              it.borderedQty = 1;
              it.invStatus = it.invStatusId;
              it.bigUom = it.uom;
            }else{
              it.borderedQty = 1;
              it.invStatus = it.invType;
              it.bigUom = it.uom;
            }
            it.uid = this.genID(10);
          });

          if(this.type=='sale'){
            for (const i in data.data) {
              getRetailPrice({productId:data.data[i].productCode}).then(res=>{
                this.data[i].retailPrice = res.data.price
                this.tabShow = false
                this.$nextTick(()=>{
                  this.tabShow = true
                })
              })
            }
          }else if(this.type=='channel'){
            for (const i in data.data) {
              getfxPrice({productId:data.data[i].productCode,orgId:data.data[i].orgCode,cisCode: this.cisCode}).then(res=>{
                this.data[i].retailPrice = res.data.list[0].standardPrice
                this.data[i].standardPrice = res.data.list[0].standardPrice
                this.tabShow = false
                this.$nextTick(()=>{
                  this.tabShow = true
                })
              })
            }
          }

          this.selRows = []; //切换页码清空勾选的行
          this.onlyShowChecked = false;
          this.pager.count = data.page.totalCount;
          this.dataCache = data.data; //缓存列表数据，前端分页
          this.data = data.data.slice(start, start + this.pager.pageSize);
        });
      }

    },
    judgeRoute(){
      if(this.$route.path === '/saleInput'){
        this.searchTipShow = true
      }
    }
  }
};
</script>

<style lang="less" scoped>
.product-model {
  /deep/ .ant-modal-content {
    border-radius: 10px;
  }

  /deep/ .ant-modal-close-x {
    width: 42px;
    height: 42px;
    border-top-right-radius: 10px;
    line-height: 42px;

    .close-icon {
      width: 42px;
      height: 42px;
      text-align: center;
      line-height: 42px;

      i {
        //color: #00aaa6;
        font-size: 16px;
      }
    }

    &:hover {
      background: #e4f8f7;

      i {
        color: #00aaa6;
      }
    }
  }

  .heade-top {
    margin-bottom: 12px;

    .auto-input {
      width: 360px;
    }

    .star {
      color: rgba(217, 22, 28, 1);
    }

    .auto-btn {
      margin-left: 16px;
    }

    .top {
      text-align: center;
    }
    .searchBtn{
      margin:6px 0px 0px 390px;
      display: flex;
      align-items: center;
    }

    span {
      font-size: 14px;
      font-weight: 500;
      color: #262626;
      line-height: 20px;
    }

    ///deep/ .ant-input-affix-wrapper .ant-input-suffix {
    //  right: -2px;
    //}
    //
    ///deep/ .ant-input {
    //  width: 270px;
    //  height: 32px;
    //  display: inline-block;
    //  margin-left: 12px;
    //}
    //
    ///deep/ .ant-input:hover {
    //  box-shadow: 0px 0px 3px 2px rgba(152, 251, 248, 0.34);
    //  border-radius: 4px;
    //  border: 1px solid #00aaa6;
    //}
    //
    ///deep/ .ant-input-affix-wrapper {
    //  width: 270px;
    //  height: 32px;
    //}
  }

  /deep/ .ant-modal {
    width: 1188px !important;
    margin: 0 auto !important;

    .ant-modal-title {
      font-size: 14px !important;
      font-weight: 500 !important;
      color: #262626 !important;
      line-height: 20px !important;
    }

    .ant-modal-body .ant-table-column-title {
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
    }
  }

  .count-span {
    font-size: 14px;
    font-weight: 400;
    color: #777777;
    line-height: 20px;
    text-align: left;
    margin-top: 17px;
    float:left;
    margin-bottom: 12px
  }
  .btnGroup{
     float:left;
      width:150px;
      margin-top:17px;
      margin-bottom: 12px;
      margin-left: 24px
    }

  .page-check-wrap {
    padding-top: 8px;
    padding-bottom: 6px;

    .check-wrap {
      float: left;
      padding-top: 12px;
    }

    .footer-pager {
      float: right;
    }
  }

  .footer-btn-wrap {
    display: flex;
    justify-content: center;
    padding-top: 20px;

    .h-btn {
      margin: 0 15px;
    }
  }

  /deep/ .ant-table-tbody > tr > td {
    padding: 8px 16px;
  }
}

.dataNull {
  margin: 0 auto;
  margin-bottom: 24px;
  width: 100%;
  height: 340px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 160px;
    height: 120.24px;
    margin-bottom: 16px;
    margin-left: 23px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    color: #777777;
    line-height: 20px;
  }
}
.btn-a{
  color: #1890ff;
}
.icon-shouye-sousuo:hover{
  color: #00aaa6;
}
</style>
